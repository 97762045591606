import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue')
  },
  {
    path: '/500',
    name: '500',
    component: () => import(/* webpackChunkName: "500" */ '../views/500.vue')
  },
  {
    path: '/top-a',
    name: 'topa',
    component: () => import(/* webpackChunkName: "topa" */ '../views/top/top-a.vue')
  },
  {
    path: '/top-b',
    name: 'topb',
    component: () => import(/* webpackChunkName: "topb" */ '../views/top/top-b.vue')
  },
  {
    path: '/top-c',
    name: 'topc',
    component: () => import(/* webpackChunkName: "topc" */ '../views/top/top-c.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/settings',
    name: 'usesetting',
    component: () => import(/* webpackChunkName: "usesetting" */ '../views/setting/use-setting.vue')
  },
  {
    path: '/share/',
    name: 'sns',
    component: () => import(/* webpackChunkName: "sns" */ '../views/share/sns.vue')
  },
  {
    path: '/setting/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/setting/profile.vue')
  },
  {
    path: '/info/rule/',
    name: 'rule',
    component: () => import(/* webpackChunkName: "rule" */ '../views/info/rule.vue')
  },
  {
    path: '/info/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "rule" */ '../views/info/terms.vue')
  },
  {
    path: '/info/legal',
    name: 'legal',
    component: () => import(/* webpackChunkName: "legal" */ '../views/info/legal.vue')
  },
  {
    path: '/info/shop',
    name: 'info',
    component: () => import(/* webpackChunkName: "shopinfo" */ '../views/info/shop.vue')
  },
  {
    path: '/info/map',
    name: 'shopmap',
    component: () => import(/* webpackChunkName: "shopinfo" */ '../views/info/map.vue')
  },
  {
    path: '/notification/list',
    name: 'notification_list',
    component: () => import(/* webpackChunkName: "notificationlist" */ '../views/notification/list.vue')
  },
  {
    path: '/notification/detail',
    name: 'notification_detail',
    component: () => import(/* webpackChunkName: "notificationdetail" */ '../views/notification/detail.vue')
  },
  {
    path: '/stamp/list',
    name: 'stamp_list',
    component: () => import(/* webpackChunkName: "stamp_list" */ '../views/stamp/list.vue')
  },
  {
    path: '/stamp/detail',
    name: 'stamp_detail',
    component: () => import(/* webpackChunkName: "stamp_detail" */ '../views/stamp/detail.vue')
  },
  {
    path: '/stamp/bonuslist',
    name: 'stamp_bonuslist',
    component: () => import(/* webpackChunkName: "stamp_bonuslist" */ '../views/stamp/bonuslist.vue')
  },
  {
    path: '/stamp/memo_list',
    name: 'stamp_memolist',
    component: () => import(/* webpackChunkName: "stamp_memolist" */ '../views/stamp/memo_list.vue')
  },
  {
    path: '/stamp/memo_regist',
    name: 'memo_regist',
    component: () => import(/* webpackChunkName: "memo_regist" */ '../views/stamp/memo_regist.vue')
  },
  {
      path: '/point/user',
      name: 'user',
      component: () => import(/* webpackChunkName: "user" */ '../views/point/user.vue')
  },
  {
    path: '/point/point-a',
    name: 'point-a',
    component: () => import(/* webpackChunkName: "point-a" */ '../views/point/point-a.vue')
  },
  {
    path: '/point/point-b',
    name: 'point-b',
    component: () => import(/* webpackChunkName: "point-b" */ '../views/point/point-b.vue')
  },
  {
    path: '/wpoint',
    name: 'wpoint',
    component: () => import(/* webpackChunkName: "wpoint" */ '../views/wpoint/index.vue')
  },
  {
    path: '/booking/detail',
    name: 'booking',
    component: () => import(/* webpackChunkName: "booking_detail" */ '../views/booking/detail.vue')
  },
  {
    path: '/booking/complete',
    name: 'booking_complete',
    component: () => import(/* webpackChunkName: "booking_complete" */ '../views/booking/complete.vue')
  },
  {
    path: '/booking/shoplist',
    name: 'booking_shops',
    component: () => import(/* webpackChunkName: "select_shop" */ '../views/shop/list.vue')
  },
  {
    path: '/calendar/shoplist',
    name: 'calendar_shops',
    component: () => import(/* webpackChunkName: "select_shop" */ '../views/shop/list.vue')
  },
  {
    path: '/info/shoplist',
    name: 'info_shops',
    component: () => import(/* webpackChunkName: "select_shop" */ '../views/shop/list.vue')
  },
  {
    path: '/movie/shoplist',
    name: 'movie_shops',
    // 20201224 NEW_DEV-743 cyber 肖 start
    component: () => import(/* webpackChunkName: "select_shop" */ '../views/shop/list.vue')
    // 20201224 NEW_DEV-743 cyber 肖 end
  },
  {
    path: '/map/shoplist',
    name: 'map_shops',
    component: () => import(/* webpackChunkName: "select_shop" */ '../views/shop/shopBase.vue')
  },
  {
    path: '/map/map',
    name: 'map',
    component: () => import(/* webpackChunkName: "select_shop" */ '../views/shop/map.vue')
  },
  {
    path: '/menu/shoplist',
    name: 'menu_shops',
    component: () => import(/* webpackChunkName: "select_shop" */ '../views/shop/list.vue')
  },
  {
    path: '/photo/shoplist',
    name: 'photo_shops',
    component: () => import(/* webpackChunkName: "select_shop" */ '../views/shop/list.vue')
  },
  {
    path: '/notification/shoplist',
    name: 'notification_shops',
    component: () => import(/* webpackChunkName: "select_shop" */ '../views/shop/list.vue')
  },
  {
    path: '/coupon/shoplist',
    name: 'coupon_shops',
    component: () => import(/* webpackChunkName: "select_shop" */ '../views/shop/list.vue')
  },
  {
    path: '/stamp/shoplist',
    name: 'stamp_shops',
    component: () => import(/* webpackChunkName: "select_shop" */ '../views/shop/list.vue')
  },
  {
    path: '/talk/shoplist',
    name: 'talk_shops',
    component: () => import(/* webpackChunkName: "select_shop" */ '../views/talk/list.vue')
  },
  {
    path: '/talk/talk',
    name: 'talk',
    component: () => import(/* webpackChunkName: "select_shop" */ '../views/talk/comment_list.vue')
  },
  {
    path: '/digital_ticket/shoplist',
    name: 'digital_ticket_shops',
    component: () => import(/* webpackChunkName: "select_shop" */ '../views/shop/list.vue')
  },
  {
    path: '/coupon/list',
    name: 'coupon_list',
    component: () => import(/* webpackChunkName: "list" */ '../views/coupon/list.vue')
  },
  {
    path: '/coupon/detail',
    name: 'coupon_detail',
    component: () => import(/* webpackChunkName: "detail" */ '../views/coupon/detail.vue')
  },
  {
    path: '/login/index',
    name: 'index',
    component: () => import(/* webpackChunkName: "loginindex" */ '../views/login/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/login.vue')
  },
  {
    path: '/register_email',
    name: 'register_email',
    component: () => import(/* webpackChunkName: "register_email" */ '../views/login/register_email.vue')
  },
  {
    path: '/check_authcode',
    name: 'check_authcode',
    component: () => import(/* webpackChunkName: "check_authcode" */ '../views/login/check_authcode.vue')
  },
  {
    path: '/register_account',
    name: 'register_account',
    component: () => import(/* webpackChunkName: "register_account" */ '../views/login/register_account.vue')
  },
  {
    path: '/login_process',
    name: 'login_process',
    component: () => import(/* webpackChunkName: "login_process" */ '../views/login/login_process.vue')
  },

  /** TODO mock start */
  {
    path: '/mock/register_app_account',
    name: 'mock-new_app_account',
    component: () => import('../views/_mock/app_omni_member_add_html.vue')
  },
  {
    path: '/mock/edit_app_account',
    name: 'mock-edit_app_account',
    component: () => import('../views/_mock/app_omni_member_edit_html.vue')
  },
  {
    path: '/mock/edit_ec_account',
    name: 'mock-edit_ec__account',
    component: () => import('../views/_mock/ec_omni_member_edit_html.vue')
  },

  {
    path: '/mock/change_password',
    name: 'mock-change_password',
    component: () => import('../views/_mock/member_password_html.vue')
  },
  {
    path: '/mock/lost_password',
    name: 'mock-lost_password',
    component: () => import('../views/_mock/password_html.vue')
  },
  {
    path: '/mock/change_mail',
    name: 'mock-change_mail',
    component: () => import('../views/_mock/member_mail_address_html.vue')
  },
  {
    path: '/mock/quit',
    name: 'mock-quit',
    component: () => import('../views/_mock/quit_member.vue')
  },
  {
    path: '/mock/pf_token_for_ec',
    name: 'mock-pf_token_for_ec',
    component: () => import('../views/_mock/pf_token_for_ec.vue')
  },
  /** mock end */

  {
    path: '/follow-map/location_permission',
    name: 'location_permission',
    component: () => import(/* webpackChunkName: "location_permission" */ '../views/follow-map/location_permission.vue')
  },
  {
    path: '/follow-map/map',
    name: 'follow_map',
    component: () => import(/* webpackChunkName: "followmap" */ '../views/follow-map/map.vue')
  },
  {
    path: '/mypage/handover',
    name: 'handover',
    component: () => import(/* webpackChunkName: "handover" */ '../views/mypage/handover.vue')
  },
  {
    path: '/mypage/handover_info',
    name: 'handover_info',
    component: () => import(/* webpackChunkName: "handover_info" */ '../views/mypage/handover_info.vue')
  },
  {
    path: '/menu/list',
    name: 'menu',
    component: () => import(/* webpackChunkName: "menulist" */ '../views/menu/list.vue')
  },
  {
    path: '/menu/category',
    name: 'menu_category',
    component: () => import(/* webpackChunkName: "menucategory" */ '../views/menu/category.vue')
  },
  {
    path: '/movie/index',
    name: 'movie',
    component: () => import(/* webpackChunkName: "movie" */ '../views/movie/index.vue')
  },
  {
    path: '/photo/index',
    name: 'photo',
    component: () => import(/* webpackChunkName: "photo" */ '../views/photo/index.vue')
  },
  {
    path: '/digital_ticket/list',
    name: 'digital_list',
    component: () => import(/* webpackChunkName: "reset" */ '../views/digital_ticket/list.vue')
  },
  {
    path: '/digital_ticket/detail',
    name: 'digital_detail',
    component: () => import(/* webpackChunkName: "reset" */ '../views/digital_ticket/detail.vue')
  },
  {
    path: '/digital_ticket/pay',
    name: 'digital_pay',
    component: () => import(/* webpackChunkName: "reset" */ '../views/digital_ticket/pay.vue')
  },
  {
    path: '/digital_ticket/cancel',
    name: 'digital_cancel',
    component: () => import(/* webpackChunkName: "reset" */ '../views/digital_ticket/cancel.vue')
  },
  {
    path: '/digital_ticket/use',
    name: 'digital_buy',
    component: () => import(/* webpackChunkName: "reset" */ '../views/digital_ticket/use.vue')
  },
  {
    path: '/calendar/',
    name: 'calendar',
    component: () => import(/* webpackChunkName: "select_shop" */ '../views/calendar/index.vue')
  },
  {
    path: '/mypage/',
    name: 'mypage',
    component: () => import(/* webpackChunkName: "select_shop" */ '../views/mypage/index.vue')
  },
  {
    path: '/ticket/use_complete',
    name: 'ticket_use_complete',
    component: () => import(/* webpackChunkName: "select_shop" */ '../views/ticket/complete.vue')
  },
  {
    path: '/ticket/use_error',
    name: 'ticket_use_error',
    component: () => import(/* webpackChunkName: "select_shop" */ '../views/ticket/error.vue')
  },
  {
    path: '/mop/',
    name: 'mop',
    component: () => import(/* webpackChunkName: "select_shop" */ '../views/mop/index.vue')
  },
  {
    path: '/tutorial',
    name: 'tutorial',
    component: () => import( '../components/tutorial.vue')
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "NotFound" */ '../views/404.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/sp/',
  routes,
  /* eslint-disable */
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeOut(() => {
          resolve(savedPosition)
        })
      })
    } else {
      return { x: 0, y: 0 }
    }
  }
  /* eslint-enable */
})

export default router
