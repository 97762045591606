<template>
  <v-main>
    <!-- 共通ダイアログ表示 -->
    <v-dialog v-model="dialog" persistent>
      <v-card class="ticket_dialogue text-center pa-5">
        <h3 class="subtitle-1 font-weight-bold mb-10">{{ title }}</h3>
        <p class="body-2 mb-10">{{ message }}</p>
        <div class="text-center">
          <v-btn v-if="type == 1" rounded class="maincolor-bg default_button" @click="close()">閉じる</v-btn>
          <v-btn v-if="type == 2" rounded class="maincolor-bg default_button" @click="reload()">再アクセス</v-btn>
          <v-btn v-if="type == 3" rounded class="maincolor-bg default_button" @click="close()">OK</v-btn>
          <v-btn v-if="type == 4" rounded class="maincolor-bg default_button" @click="back()">閉じる</v-btn>
          <v-btn v-if="type == 5" rounded class="maincolor-bg default_button" @click="goToUse()">閉じる</v-btn>
          <v-btn v-if="type == 6" rounded class="maincolor-bg default_button" @click="goToLost()">パスワード再発行</v-btn>
          <v-btn v-if="type == 7" rounded class="maincolor-bg default_button" @click="goToLogin()">ログインする</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    }
  },

  props: {
    title: {
      type: String,
    },
    message: {
      type: String,
    },
    type: {
      type: Number,
    },
    id: {
      type: Number,
    },
    //20210121 NEW_DEV-768 cyber 李 start
    history_id: {
      type: Number,
    }

  },

  created() {
    this.$mount();
    document.body.appendChild(this.$el);
    this.dialog = true;

    if(!this.init?.color_code) {
      this.$set(this, 'maincolor', '#E1333E');
      const $ = el => document.querySelector(el);
      this.$root.maincolorop = 'rgba(225,51,62,0.1)'
      $(':root').style.setProperty('--main_color', '#E1333E');
      $(':root').style.setProperty('--main_color_op', 'rgba(225,51,62,0.1)');
    }
  },

  methods: {
    close() {
      this.dialog = false;
      setTimeout(() => {
        if (document.body.contains(this.$el)) document.body.removeChild(this.$el);
        this.$destroy();
      }, 200);
    },
    back() {
      this.dialog = false;
      this.$router.go(-1)
    },
    goToUse() {
      this.dialog = false;
      this.$router.push({ name: 'digital_buy', query: {ticket_id: this.id, history_id: this.history_id}})
      //20210121 NEW_DEV-768 cyber 李 end
    },
    goToLost() {
        this.dialog = false;
        this.$router.push({ name: 'lost_password'});
      },
    goToLogin() {
        this.dialog = false;
        this.$router.push({ name: 'login'});
      },
  },
}
</script>
