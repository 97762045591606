import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import InfiniteLoading from 'vue-infinite-loading'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import VueCryptojs from 'vue-cryptojs'
import VueGtag from "vue-gtag"
Vue.use(VueCryptojs)

import * as VueGoogleMaps from 'vue2-google-maps'
import common from '@/utils/common.js'

import animate from 'animate.css'

Vue.use(animate)
Vue.component(VueQrcode.name, VueQrcode);

Vue.config.productionTip = false

Vue.prototype.$axios = axios

Vue.use(InfiniteLoading)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDqNDWgcqNmLGgNXQO73qbTiQtJpClDaqM',
    libraries: 'places'
  },
  installComponents: true,
  autobindAllEvents: false
})

Vue.mixin(common);

Vue.filter('truncate', function(value, length) {
  if (value.length <= length) {
    return value;
  }

  return value.substring(0, length) + '...';
});

Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GA_ID }
  },
  router
)

new Vue({
  data: {
    init_flg: 0,
    maincolor: null,
    maincolorop: null,
    digiticketTab: null,
  },
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
