import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 初期値設定
    // area, shops, followShops
    selectedShopTab: 'shops',
  },
  mutations: {
    setSelectedShopTab: function (state, tabName) {
      state.selectedShopTab = tabName;
    }
  },
  actions: {
  },
  modules: {
  }
})
